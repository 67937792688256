
import Vue from 'vue';
import Component from 'vue-class-component';
//import styles from './app.css';
import StackTrace from 'stacktrace-js';
import { ShowError } from './ShowError';

import Menu from './components/Menu.vue';
import { QueryParamLoader } from './winIproInterop/QueryParamLoader';
import { CompanyDashboardApi } from './companyDashboardAPI/CompanyDashboardAPI';
import { WinIproStateLoader } from './winIproInterop/WinIproStateLoader';
import { TranslationAPI } from './translationAPI/TranslationAPI';
import { Branch } from './companyDashboardAPI/Branch';

@Component({
  components: {
    Menu: Menu
  }
})
export default class App extends Vue {
  private winIproState: WinIproStateLoader = new QueryParamLoader(
    this.$route.query
  );
  private drawer = null;
  private theme = this.$vuetify.theme.dark ? 'dark' : 'light';
  public ApiClient: CompanyDashboardApi | null = null;
  public dialog = true;
  private CompanyId = '';
  public companyLogo = '';
  public isLoading = true;

  private components() {
    Menu;
  }

  private async mounted() {
    try {
      const { authToken, companyMiddlewareId, branchId, middlewareEnviroment } =
        this.winIproState.load();

      // Check role in token
      const tokenParts = authToken.split('.');
      if (tokenParts[1] != undefined) {
        const tokenPayload = JSON.parse(atob(tokenParts[1]));

        if (tokenPayload.realm_access.roles.indexOf('sinno_dashboard') == -1) {
          throw new ShowError(
            this.$vuetify.lang.t(`$vuetify.errors_noPermissionRoleMissing`)
          );
        }
      }

      this.CompanyId = companyMiddlewareId;

      this.companyLogo =
        `https://sinnoapp.api${middlewareEnviroment == 'staging' ? '-test' : ''
        }.ipro.de/v1/Companies/` +
        companyMiddlewareId +
        '/Settings/CompanyLogo';

      this.ApiClient = new CompanyDashboardApi(
        `https://company-dashboard.api${middlewareEnviroment == 'staging' ? '-test' : ''
        }.ipro.de/v1`,
        companyMiddlewareId,
        authToken
      );

      await this.initTranslation(
        branchId,
        companyMiddlewareId,
        authToken,
        middlewareEnviroment
      );
      this.isLoading = false;

      // Set page title
      document.title = this.$vuetify.lang.t(`$vuetify.dashboardTitel`);
      
      this.$router.push({ name: 'Home' });
    } catch (error_) {
      const error = error_ as Error;
      let endUserMessage: string;

      if (error.name == 'WinIproInteropError') {
        endUserMessage = 'An error occurred in the communication with winIPRO!'; // Can´t get translations there
      } else if (error.name == 'MiddlewareContractViolation') {
        endUserMessage = 'An error occurred in communication with the cloud!'; // Can´t get translations there
      } else if (error.name == 'ShowError') {
        endUserMessage = error.message;
      } else {
        endUserMessage = 'An unknown error has occurred!'; // Can´t get translations there
      }

      const trace = (await StackTrace.fromError(error)).reduce<string>(
        (acc, curr) => acc + `\n  at ${curr}`,
        ''
      );

      if (!(this.$route.name == 'Error')) {
        await this.$router.push({
          name: 'Error',
          params: {
            endUserMessage: endUserMessage,
            technicalMessage: error.toString() + trace
          }
        });
      }
    }
  }

  private async initTranslation(
    branchId: string,
    companyId: string,
    authToken: string,
    environment: string
  ) {
    if (!this.ApiClient) {
      return;
    }

    const branches = await this.ApiClient.getBranches().next();
    const branch = branches.value
      ? branches.value.find(
        (branch: Branch) => branch.branchIdIPRO === parseInt(branchId)
      )
      : null;
    const langCode = branch ? branch.language : 'en';

    const translationAPI = new TranslationAPI(
      `https://sinnoapp.api${environment == "staging" ? "-test" : ""}.ipro.de/v1`,
      companyId,
      branch?.branchId.toString() || ""
    );

    // Load default en
    await translationAPI.loadTranslation('en').then((translation) => {
      this.$vuetify.lang.locales.en = translationAPI.mergeDeep(
        this.$vuetify.lang.locales.en,
        translationAPI.createTranslations(translation)
      );
    }).catch(error => {
      debugger
      console.error(error)
    });

    // Load branch language
    await translationAPI.loadTranslation(langCode).then((translation) => {
      this.$vuetify.lang.locales[langCode] = translationAPI.mergeDeep(
        this.$vuetify.lang.locales.en,
        translationAPI.createTranslations(translation)
      );
      this.$vuetify.lang.current = langCode;
    }).catch(error => {
      console.info(error);
    });
  }
}
