import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

import HomeIcon from '@/components/icons/HomeIcon.vue'
import GearIcon from '@/components/icons/GearIcon.vue'

const opts = {
    icons: {
        values: {
            homeIcon: {
                component: HomeIcon
            },
            gearIcon: {
                component: GearIcon,
                props: {
                    name: "sinno-gear"
                }
            }
        }
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#0062ae',
                primaryContrast: '#ffffff',
                secondary: '#e3e3e3',
                secondaryContrast: '#0062ae'
                //background: '#eeeeee'
            }
        },
        dark: false
    }
};

export default new Vuetify(opts);