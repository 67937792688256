import { Dictionary } from 'vue-router/types/router';
import { WinIproInteropError } from './WinIproInteropError';
import { WinIproState } from './WinIproState';
import { WinIproStateLoader } from './WinIproStateLoader';

export class QueryParamLoader implements WinIproStateLoader {
  public constructor(queryParams: Dictionary<string | (string | null)[]>) {
    this.params = queryParams;
  }

  public load(): WinIproState {
    const version = process.env.VUE_APP_QUERY_PARAM_VERSION ?? this.valueOfOrFromLocalStorage('version');
    const env = process.env.VUE_APP_QUERY_PARAM_ENVIRONMENT ?? this.valueOfOrFromLocalStorage(
      'middleware-environment',
      'production'
    );
    const authToken = process.env.VUE_APP_ACCESS_TOKEN ?? this.valueOfOrFromLocalStorage('auth-token');
    const branchId = process.env.VUE_APP_QUERY_PARAM_BRANCH_ID ?? this.valueOfOrFromLocalStorage('branch-id');
    const companyMiddlewareId = process.env.VUE_APP_QUERY_PARAM_COMPANY_ID ?? this.valueOfOrFromLocalStorage(
      'company-middleware-id'
    );

    if (version != '1') {
      throw new WinIproInteropError('Bad interop api version');
    }

    if (env != 'staging' && env != 'production') {
      throw new WinIproInteropError(`Bad param "middleware-environment"`);
    }

    if (authToken == '') {
      throw new WinIproInteropError(`Bad param "auth-token"`);
    }

    if (branchId == '') {
      throw new WinIproInteropError(`Bad param "branch-id"`);
    }

    if (companyMiddlewareId == '') {
      throw new WinIproInteropError(`Bad param "company-middleware-id"`);
    }

    return {
      version: version,
      authToken: authToken,
      branchId: branchId,
      companyMiddlewareId: companyMiddlewareId,
      middlewareEnviroment: env
    };
  }

  private valueOfOrFromLocalStorage(param: string, defaultValue = ''): string {
    let value = this.params[param];

    if (typeof value != 'string') {
      value = window.localStorage.getItem(param) ?? defaultValue;
    }

    // Nur für die Entwickler! Kann ein Sicherheitsrisiko sein, da der
    // Access-Token aus dem Browser ausgelesen werden kann.
    //if (value != '') window.localStorage.setItem(param, value);

    return value;
  }

  private readonly params: Dictionary<string | (string | null)[]>;
}
