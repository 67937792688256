import { MiddlewareContractViolation } from '@/MiddlewareContractViolation';

export interface LanguageListItem {
  Name: string;
  Text: string;
}
export interface LanguageList {
  LanguageCode: string;
  List: LanguageListItem[]
}
export interface GetLanguages {
  Languages: LanguageList[];
}


export class TranslationAPI {
  private readonly baseUrl: string;
  private readonly companyId: string;
  private readonly branchId: string;

  public constructor(baseUrl: string, companyId: string, branchId: string) {
    this.baseUrl = baseUrl;
    this.companyId = companyId;
    this.branchId = branchId;
  }

  public mergeDeep(target: Record<string, any>, source: Record<string, any>): Record<string, any> {
    const hasOwnProperty = Object.prototype.hasOwnProperty;

    for (const key in source) {
        if (hasOwnProperty.call(source, key)) {
            if (typeof source[key] === 'object' && source[key] !== null) {
                if (!target[key]) {
                    target[key] = {};
                }

                this.mergeDeep(target[key], source[key]);
            } else {
                target[key] = source[key];
            }
        }
    }
    return target;
}


  public createTranslations(translation: GetLanguages) : Record<string, any> {
    const texts: Record<string, any> = {}

    if (translation.Languages.length === 0) {
      return texts
    }

    translation.Languages[0].List.map(item => {
      const nameParts = item.Name.split('.');
      let currentLevel = texts;

      nameParts.forEach((part, index) => {
        if (index === nameParts.length - 1) {
            currentLevel[part] = item.Text;
        } else {
            if (!currentLevel[part]) {
                currentLevel[part] = {};
            }
            
            currentLevel = currentLevel[part];
        }
      });
    });

    return texts
  }

  public async loadTranslation(langCode: string): Promise<any> {
    const url = new URL(`${this.baseUrl}/App/Languages`);
    if (this.companyId > '') {
      url.searchParams.set('CompanyId', this.companyId);
    }
    if (this.branchId > '') {
      url.searchParams.set('BranchId', this.branchId);
    }
    url.searchParams.set('LanguageId', langCode);

    return await fetch(url.toString(), {
      headers: {
        'X-IPROSINNO-APP': 'Sinno-Dashboard'
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw new MiddlewareContractViolation(`unexpected status ${response.status}`, 'sinno', 'languages');          
        }

        return response.json();
      })
      .catch((_error) => {
        const error = _error as Error;
        throw new MiddlewareContractViolation(`unexpected status ${error.message}`, 'sinno', 'languages');
      });
  }
}
